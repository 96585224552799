import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Loading from "../../components/loading/Loading";
import Home from "./Categories";
import { useStaffStore } from "./../../store/staff";
const Category = () => {
  const [component, setComponent] = useState(<Loading />);
  const location = useLocation();
  const { staff } = useStaffStore();
  useEffect(() => {
    const pathname = location.pathname.split("/");
    switch (pathname[2]) {
      default:
        setComponent(<Home staff={staff} />);
    }
  }, [location]);

  return component;
};

export default Category;
