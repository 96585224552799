import React from "react";
import {
  Box,
  Spinner,
  Text,
  SimpleGrid,
  GridItem,
  Flex,
  Icon,
  Button,
} from "@chakra-ui/react";
import {
  ContentType,
  SortOrder,
  useLessonsQuery,
  useQuestionsQuery,
} from "../../generated/graphql";
import ReactPlayer from "react-player";
import { DecodedUser } from "../../store/staff";
import { ImRadioUnchecked } from "react-icons/im";
import Question from "../../components/question/Question";

interface Props {
  id: string;
  userId: DecodedUser | null | undefined;
}

const Categories: React.FC<Props> = ({ id, userId }) => {
  const { data, loading, error } = useLessonsQuery({
    variables: {
      where: {
        category: {
          is: { id: { equals: id } },
        },
      },
      contentsWhere2: {
        contentType: {
          equals: ContentType.VIDEO,
        },
      },
    },
    fetchPolicy: "network-only",
  });

  const { data: questionData, loading: questionLoading } = useQuestionsQuery({
    variables: {
      where: {
        categoryId: {
          equals: id,
        },
      },
      answersWhere2: {
        userId: {
          equals: userId?.id,
        },
      },
      orderBy: {
        order: SortOrder.asc,
      },
    },
    fetchPolicy: "network-only",
  });
  return (
    <Box>
      {loading ? (
        <Spinner />
      ) : error ? (
        <Text>Error</Text>
      ) : data ? (
        <Box
          maxWidth={{
            base: "100%",
            md: "80%",
          }}
        >
          <Box mb={4} fontWeight="bold">
            {data.lessons[0].descriptions[0].title}
          </Box>
          <div className="player-wrapper">
            <ReactPlayer
              className="react-player"
              controls
              width={"100%"}
              height={"100%"}
              url={data?.lessons[0]?.contents[0]?.video || ""}
            />
          </div>
          {userId?.id && (
            <Box mt={6}>
              {questionLoading ? (
                <Spinner />
              ) : (
                questionData?.questions.map((q, i) => {
                  return <Question userId={userId.id} i={i} q={q} />;
                })
              )}
            </Box>
          )}
        </Box>
      ) : (
        <Text>No data</Text>
      )}
    </Box>
  );
};

export default Categories;
