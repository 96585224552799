import { Box, Heading, SimpleGrid } from "@chakra-ui/react";
import ReactPlayer from "react-player";

function Dashboard() {
  return (
    <Box p={4} bgColor="white" rounded="base">
      <strong>GİRİŞ</strong>
      <SimpleGrid columns={2} mb={6} gap={6}>
        <Box>
          <Box>Eğitimci Bilgilendirme</Box>
          <ReactPlayer
            width={"100%"}
            controls
            height="100%"
            url={"https://zone.interaktifis.com/ilgi/egitim-bilgilendirme.mp4"}
          />
        </Box>

        <Box>
          <Box>Aile Bilgilendirme</Box>
          <ReactPlayer
            controls
            width={"100%"}
            height="100%"
            url={"https://zone.interaktifis.com/ilgi/aile-bilgilendirme.mp4"}
          />
        </Box>
      </SimpleGrid>
      <Box mb={2}>
        Bu öğrenme materyali, anaokulu/okul öncesi öğretmenlerine, yöneticilerine,
        psikologlarına, yardımcı öğretmenlere (gölge öğretmen, kolaylaştırıcı personel)
        otizmli çocukları fark etme, ailelerini bilgilendirme, tıbbi tanı ve eğitsel
        değerlendirmeye doğru ve uygun bir şekilde yönlendirme, tanı sonrası erken
        müdahale destek çevresinin oluşturulması için aile-okul öncesi eğitim kurumu-özel
        eğitim rehabilitasyon merkezi-bakıcılar-sosyal çevre koordinasyonunu sağlama
        konularında bilgi, beceri ve yetkinlik kazandırmak amacıyla geliştirilmiştir.
      </Box>
      <Box fontWeight={"bold"} mb={2}>
        Öğrenme Materyalinin Amaçları
      </Box>
      <ol>
        <li>
          Bu öğrenme materyali; eğitim profesyonellerinin otizm riski taşıyan çocuğu fark
          etmesi, eğitim ortamlarının ve metotlarının düzenlenmesi, davranış yönetimi,
          çocuğun devam eden süreçteki eğitimsel atmosferinin tasarlanması, eğitimcilere
          koordine etme ve yapılandırma için ihtiyaç duyacakları bilgi, beceri ve
          yetkinliklerin kazandırılması amacıyla hazırlanmıştır.{" "}
        </li>
        <li>
          Öğretim materyalinin içeriği; konular hakkında bilgilendirme, uygulamaya ilişkin
          örnekler ile ölçme ve değerlendirmeyi kapsayacak şekilde geliştirilmiştir. Bu
          materyal programda yer alan konu başlıkları ve öğrenme kazanımları doğrultusunda
          aşağıdaki altı öğrenme faaliyetinden oluşmaktadır:{" "}
        </li>
        <li>Çocuklarının gelişim özelliklerini takip etme,</li>
        <li>Otizm riski taşıyan çocuğu fark etme,</li>
        <li>Yönlendirme öncesi olarak çocuk ile ilgili bilgi/ veri toplama,</li>
        <li>Aileyi yönlendirme ve ailenin takibi,</li>
        <li>Kaynaştırma eğitimi ve davranış yönetimi,</li>
        <li> Koordine etme ve hizmetlerin gözden geçirilmesidir.</li>
      </ol>
    </Box>
  );
}

export default Dashboard;
