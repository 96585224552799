import {
  Box,
  Container,
  SimpleGrid,
  GridItem,
  Button,
  FormControl,
  FormLabel,
  Input,
  chakra,
  useToast,
  FormHelperText,
  Text,
  Image,
  Flex,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useLoginMutation } from "../generated/graphql";
import { useStaffStore } from "../store/staff";
import { Link } from "react-router-dom";
type Inputs = {
  email: string;
  password: string;
};

const schema: yup.SchemaOf<Inputs> = yup.object({
  email: yup.string().email().required(),
  password: yup.string().min(3).required(),
});

function Login() {
  const loginUser = useStaffStore((store) => store.loginStaff);
  const [loginMutation, { loading }] = useLoginMutation();
  const toast = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: Inputs) => {
    try {
      const res = await loginMutation({
        variables: { data },
      });
      loginUser(res.data!.login);
      window.location.href = "/";
    } catch (error: any) {
      return toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  return (
    <SimpleGrid columns={{ base: 1, md: 5 }} gap={0} height="100vh">
      <GridItem colSpan={2} bgGradient="linear(to-t, #F5C649, #FDB700)">
        <Flex
          h={"full"}
          alignItems={"center"}
          justifyContent={"space-around"}
          direction={"column"}
        >
          <Box maxW={"35%"} mx={"auto"}>
            <Text
              color={"white"}
              fontFamily={"body"}
              fontWeight={"semibold"}
              fontSize={"4xl"}
              textAlign={"center"}
            >
              Beni Tanı Kullanıcı Portalı
            </Text>
          </Box>
          <Image src="/Frame1.png" mx={"auto"} mt={12} />
        </Flex>
      </GridItem>

      <GridItem bgColor="white" colSpan={3}>
        <Container h={"full"}>
          <Image src="/logo/ab_tr_isbirligi_logo2.png" mx={"auto"} my={8} />
          <Text
            color={"#F3B715"}
            fontFamily={"body"}
            fontWeight={"semibold"}
            fontSize={"4xl"}
            textAlign={"center"}
          >
            Beni Tanı Kullanıcı Portalı
          </Text>
          <Box
            h={"75%"}
            display="flex"
            justifyContent={"center"}
            flexDir="column"
            alignItems="center"
          >
            <chakra.form
              w="full"
              experimental_spaceY={8}
              onSubmit={handleSubmit(onSubmit)}
            >
              <FormControl>
                <FormLabel>E-mail</FormLabel>
                <Input size="lg" {...register("email", { required: true })} />
                <FormHelperText color="red.500">{errors.email?.message}</FormHelperText>
              </FormControl>
              <FormControl>
                <FormLabel>Password</FormLabel>
                <Input type="password" size="lg" {...register("password")} />
                <FormHelperText color="red.500">
                  {errors.password?.message}
                </FormHelperText>
              </FormControl>

              <Box display="flex" justifyContent="space-between">
                <Button
                  bg={"#31A7E1"}
                  fontFamily={"body"}
                  size="lg"
                  type="submit"
                  color={"white"}
                  px={14}
                  _hover={{ bg: "#2F95E6" }}
                  disabled={loading}
                >
                  Giriş Yap
                </Button>
                <Button
                  as={Link}
                  to="/register"
                  bg={"#B3CE35"}
                  fontFamily={"body"}
                  size="lg"
                  _hover={{ bg: "#A0C826" }}
                  px={14}
                  color={"white"}
                  type="button"
                  disabled={loading}
                >
                  Kayıt Ol
                </Button>
              </Box>
            </chakra.form>
            <Image src="/logos.png" my={8} />
          </Box>
        </Container>
      </GridItem>
    </SimpleGrid>
  );
}

export default Login;
